<template>
<div id="resumeList">
  <div class="resume-wrap">
    <div class="filter-box">
      <div class="search-box">
        <el-input v-model="conditionForm.searchValue" class="searchCt" placeholder="搜索人才、简历"></el-input>
        <div class="search-icon" @click="getResumeList">
          搜索
        </div>
      </div>
      <div class="address">
        <span>工作地点：</span>
        <label v-for="(item,index) in areaList" :key="index" @click="toggleArea(item.areaCode,index)" :class="{activeSub:areaSub==index}">
          {{item.areaName}}
        </label>
      </div>
      <div class="filter-ct">
        <el-select @change="getResumeList" clearable v-model="conditionForm.workExperience" placeholder="工作经验">
          <el-option v-for="item in workList" :key="item.dictValue" :label="item.dictLabel" :value="item.dictValue">
          </el-option>
        </el-select>
        <el-select @change="getResumeList" clearable v-model="conditionForm.education" placeholder="学历要求">
          <el-option v-for="item in backgroundTypeList" :key="item.dictValue" :label="item.dictLabel" :value="item.dictValue">
          </el-option>
        </el-select>
        <el-select @change="getResumeList" clearable v-model="conditionForm.jobStatus" placeholder="到岗时间">
          <el-option v-for="item in jobStatusList" :key="item.dictValue" :label="item.dictLabel" :value="item.dictValue">
          </el-option>
        </el-select>
        <el-dropdown  trigger="click"  @command="handleCommand">
          <span class="drop-text">
            {{expectedPositionName}}<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown" class="subdivision-menu">
              <el-dropdown-item :class="{activePosition:item.code==conditionForm.expectedPosition}"  v-for="(item,index) in subList" :key="index" :command="item">{{item.name}}</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
         <div class="reset-btn" @click="reset">重置</div>
      </div>
    </div>
    <div v-if="resumeList.length>0" class="resume-list" v-loading="loadResume">
      <div class="resume-item" v-for="(item,index) in resumeList" :key="index" @click="jumpResume(item)">
        <div class="avator">
          <img :src="item.recruitUserInfo.headSculpture" />
          <span v-show="item.resumeJobDesire.minimumWage">
            {{item.resumeJobDesire.minimumWage}}<label v-show="item.resumeJobDesire.maximumSalary">-{{item.resumeJobDesire.maximumSalary}}</label>
          </span>
        </div>
        <div class="base-info">
          <h3>{{item.recruitUserInfo.userName}} <label v-show="item.recruitUserInfo.topping==1">置顶</label></h3>
          <p>
            <label v-show="item.recruitUserInfo.age">{{item.recruitUserInfo.age}}<i></i></label>
            <label v-show="item.resumeBasicInfo.workExperienceName">{{item.resumeBasicInfo.workExperienceName}}<i></i></label>
            <label v-show="item.resumeBasicInfo.educationName">{{item.resumeBasicInfo.educationName}}<i></i></label>
            <label v-show="item.resumeJobDesire.jobStatusName">{{item.resumeJobDesire.jobStatusName}}</label>
          </p>
          <p>期望：{{item.resumeBasicInfo.areaName}}</p>
          <div class="skill">
            <span v-for="(s,key) in item.resumeJobDesire.workFunctionName.split(',')" :key="key">{{s}}</span>
          </div>
        </div>
        <div class="resume-mid">
          <div class="work-exps">
            <img src="@/assets/imgs/company/work.png" />
            <i v-show="item.resumeWorkHistoryList.length>1" class="dotted"></i>
            <div class="time-line" v-for="(t,key) in item.resumeWorkHistoryList" :key="key">
              <span>{{t.entryTime}} 至 {{t.leavedate}}</span>
              <label>{{t.unitName}}<em></em>{{t.positionName}}</label>
            </div>
          </div>
        </div>
        <div class="chat-btn" @click.stop="jumpChat(item)">
          立即沟通
        </div>
      </div>
    </div>
    <el-empty v-else :image-size="200" description="暂时没有符合该搜索条件的职位"></el-empty>
    <div class="pagination">
      <el-pagination
        @size-change="sizeChange"
        @current-change="currentChange"
        :current-page="conditionForm.pageNum"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="conditionForm.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
      </el-pagination>
     </div>
  </div>
  <resume-detail :resumeDialog="resumeDialog" :userId="userId" @closeDialog="closeDialog"></resume-detail>
</div>
</template>

<script>
import { getResumeList, getAreaList, getDictData,getJobSegmentation } from "@/api/index.js";
import resumeDetail from './resumeDetail.vue'
export default {
  name: "resumeList",
  components: {
    'resume-detail': resumeDetail,
  },
  data() {
    return {
      userInfo:{},
      resumeList: [],
      resumeDialog: false,
      userId: '',
      conditionForm: {
        expectedPosition:'',
        searchValue: '',
        city: '',
        workExperience: '',
        education:'',
        jobStatus:'',
        pageSize:10,
        pageNum:1
      },
      areaSub: 0,
      areaList: [],
      workList: [],
      backgroundTypeList: [],
      jobStatusList: [],
      total:0,
      loadResume:true,
      subList:[],
      expectedPositionName:'细分方向'
    }
  },
  mounted() {
    this.conditionForm.searchValue = this.$route.query.searchValue
    this.conditionForm.expectedPosition = this.$route.query.expectedPosition
    this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
    this.getJobSegmentation()
    this.getResumeList()
    this.getAreaList()
    this.getDictData('work_experience')
    this.getDictData('background_type')
    this.getDictData('job_status')
  },
  methods: {
    handleCommand(command){
      this.conditionForm.expectedPosition = command.code
      this.expectedPositionName = command.name
      this.getResumeList()
    },
    getJobSegmentation(){
      getJobSegmentation({
        positionCode: this.conditionForm.expectedPosition
      }).then(res=>{
        this.subList= res.rows
        this.subList.forEach(s=>{
          if(s.code == this.conditionForm.expectedPosition){
            this.expectedPositionName =s.name
          }
        })
      })
    },
    jumpChat(item){
      this.$router.push({
        path: '/chat',
        query: {
          recvId:item.recruitUserInfo.id
        }
      })
    },
     sizeChange(val){
      this.conditionForm.pageSize = val
      this.getResumeList()
    },
    currentChange(val){
      this.conditionForm.pageNum = val
      this.getResumeList()
    },
    getDictData(dictType) {
      getDictData({
        dictType: dictType
      }).then(res => {
        if (dictType == 'work_experience') {
          this.workList = res.rows
        }
        if (dictType == 'background_type') {
          this.backgroundTypeList = res.rows
        }
        if (dictType == 'job_status') {
          this.jobStatusList = res.rows
        }
       
      })
    },
    reset(){
       this.conditionForm.workExperience = ''
       this.conditionForm.expectedPosition = ''
       this.expectedPositionName = '细分方向'
       this.conditionForm.education = ''
       this.conditionForm.jobStatus = ''
       this.getResumeList()
    },
    toggleArea(areaCode,index) {
      this.conditionForm.city = areaCode
      this.areaSub = index
      this.getResumeList()
    },
    getAreaList() {
      getAreaList().then(res => {
        this.areaList = res.rows
      })
    },
    closeDialog() {
      this.resumeDialog = false
    },
    jumpResume(item) {
       if(this.userInfo.type==3){
        this.$router.push({
          path: '/login',
        })
      }else{
         this.resumeDialog = true
         this.userId = item.recruitUserInfo.id
      }
    },
    getResumeList() {
      this.loadResume = true
      getResumeList(this.conditionForm).then(res => {
        this.loadResume = false
        this.resumeList = res.rows
        this.resumeList.forEach(item=>{
          if(!item.resumeJobDesire.workFunctionName){
            item.resumeJobDesire.workFunctionName = ''
          }
        })
        this.total = res.total
      })
    },
  }
};
</script>

<style lang="scss" scoped>
#resumeList {
  width: 100%;
  min-height: 600px;
  background: linear-gradient(0deg, #F5F5F5 1%, #D6E9FF 100%);
  overflow: hidden;
  padding-top: 24px;
}
 .address{
  margin-bottom: 10px;
 }
.filter-box {
  min-height: 60px;
  padding: 25px 20px;
  background: #FFFFFF;
  border-radius: 10px;
  margin-bottom: 13px;

  span {
    font-size: 16px;
    color: #222222;
    display: inline-block;
    margin-right: 5px;
  }

  label {
    font-size: 14px;
    display: inline-block;
    margin-right: 25px;
    cursor: pointer;

  }

  .activeSub {
    color: #0077FF;
  }
  .reset-btn{
    width: 100px;
    height: 32px;
    line-height: 32px;
    margin-left: 10px;
    border-radius:4px;
    text-align: center;
    font-size: 13px;
    color: #fff;
    background: #0077FF;
   display: inline-block;
   cursor: pointer;
  }
}
.pagination{
  margin: 15px;
}
.reset-btn{
    width: 100px;
    height: 32px;
    line-height: 32px;
    margin-left: 10px;
    border-radius:4px;
    text-align: center;
    font-size: 13px;
    color: #fff;
    background: #0077FF;
   display: inline-block;
   cursor: pointer;
  }
.resume-wrap {
  width: 1200px;
  margin: 0 auto;
}

.resume-item {
  background: #FFFFFF;
  cursor: pointer;
  border-radius: 8px;
  padding: 24px 30px 15px 35px;
  min-height: 106px;
  overflow: hidden;
  position: relative;
  -webkit-transition: box-shadow 0.2s;
  transition: box-shadow 0.2s;
  margin-bottom: 12px;

  .avator {
    float: left;

    img {
      width: 65px;
      height: 65px;
      border-radius: 50%;
    }

    span {
      display: block;
      min-width: 60px;
      height: 20px;
      background: #FA6A43;
      border-radius: 10px;
      font-size: 13px;
      font-weight: bold;
      color: #FFFFFF;
      line-height: 20px;
      text-align: center;
      padding: 0 6px;
      margin-top: 10px;
      position: relative;
      left: -6px;
    }
  }

  .base-info {
    float: left;
    min-width: 330px;
    margin-left: 30px;
    h3{
      label{
        display: inline-block;
        width: 41px;
        height: 21px;
        line-height: 21px;
        text-align: center;
        border: 1px solid #FF2400;
        border-radius: 2px;
        color: #FF2400;
        font-size: 14px;
        margin-left: 12px;
        font-weight: 100;
      }
    }
    p {
      font-size: 13px;
      font-weight: 400;
      line-height: 18px;
      margin-top: 8px;
      color: #414a60;

      i {
        display: inline-block;
        width: 1px;
        height: 12px;
        background: #D8DDE3;
        margin: 0 10px;
        position: relative;
        top: 2px;
      }

      .dot {
        width: 4px;
        height: 4px;
        background-color: #BCC4CC;
        border-radius: 50%;
        top: -2px;
      }
    }

    .skill {
      margin-top: 10px;

      span {
        line-height: 22px;
        background: #F0F3F7;
        border-radius: 4px;
        display: inline-block;
        padding: 0 8px;
        font-size: 12px;
        font-weight: 400;
        color: #363F4D;
        margin-right: 10px;
        margin-bottom: 10px;
      }
    }
  }

  .resume-mid {
    float: left;
    min-width: 400px;
    margin-left: 42px;
    margin-top: 10px;

    .work-exps {
      position: relative;
      overflow: hidden;

      img {
        width: 16px;
        height: 16px;
        position: absolute;
      }

      .dotted {
        display: block;
        width: 0;
        height: 100%;
        border-left: 1px dashed #D8DDE3;
        background: transparent;
        position: absolute;
        top: 16px;
        left: 8px;
      }

      .time-line {
        margin-left: 30px;
        margin-bottom: 12px;

        span {
          display: inline-block;
          width: 190px;
          font-size: 14px;
          color: #7E8694;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        label {
          font-size: 14px;
          color: #7E8694;
          display: inline-block;
          position: relative;
          top: -3px;
          margin-left: 20px;

          em {
            width: 4px;
            height: 4px;
            background-color: #BCC4CC;
            border-radius: 50%;
            margin: 0 10px;
            display: inline-block;
            position: relative;
            top: -3px;
          }
        }
      }
    }
  }

  .chat-btn {
    float: right;
    width: 92px;
    height: 34px;
    line-height: 34px;
    text-align: center;
    color: #fff;
    background: #0077FF;
    border-radius: 4px;
    font-size: 14px;
    margin-top: 10px;
  }
}

.resume-item:hover {
  box-shadow: 0px 6px 15px 0px rgba(20, 61, 102, 0.08);
}
.activePosition{
    color: #0077FF;
    background: #f8f8f8;
}
</style>
<style lang="scss">
.filter-box{
  .search-box{
    margin-left: 0;
     .el-input__inner{
      width: 780px;
      border: 1px solid #0077FF;
     }
     .search-box .search-icon {
       border: 1px solid #0077FF;
     }
  }
}

</style>
